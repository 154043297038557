 
 
 export const routeConstants = {
     all: '*',
     home: '/',
     login: 'log-in',
     signUp: 'sign-up',
     forgotPasswoerd: 'forgot-password',
     resetPasswoerd: 'reset-password',
     studentLogin: 'student-login',
     apply: 'apply',
     dashboard: 'dashboard',
     studentDisbursement: 'disbursement',
     studentRepayment: 'repayment',
     fundManagement: 'fund-management',
     studentRecords: 'student-records',
     studentVerification: 'student-verification',
     pendingStudents: 'pending-students',
     auditLog: 'audit-log',
     parents: 'parents',
     children: 'children',
     familyFaith: 'family-faith',
     teachers: 'teachers',
     assessments: 'assessment',
     attendance: 'attendance',


     register: 'register',
     terms: 'terms',
     faq: 'faq',
     unauthorized: 'unauthorized',
     deactivated: 'deactivated',

     admin: 'admin',
     manager: 'manager',
     school_manager: 'school-manager',
     school_official: 'school-official',
     finance_manager: 'finance-manager',
     student: 'student',

     userManagement: 'user-management',
     profile: 'profile',
     appSettings: 'app-settings',
     settings: 'settings',

     userLevels: {
        admin: 'admin',
        manager: 'manager',
        school_manager: 'school_manager',
        school_official: 'school_official',
         finance_manager: 'finance_manager',
         student: 'student',
     },
     userLevelIds: {
        admin: 1,
        school_official: 5,
        school_manager: 2,
        agent: 4,
     },
     userLevelKeys: [
        {
            id: 1,
            name: "admin",
        },
        {
            id: 2,
            name: "school_manager",
        },
        {
            id: 4,
            name: "manager",
        },
        {
            id: 5,
            name: "school_official",
        },
        {
            id: 6,
            name: "finance_manager",
         },
         {
             id: 6,
             name: "student",
         }
    ],
 };
